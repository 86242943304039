import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import firebase from 'gatsby-plugin-firebase'

import { Page, View, Text, WhiteGreyLayout, SEO, Table, analytics, Button } from 'lib'
import { Tabs, Tab, Grid } from '@material-ui/core'
import theme from '_appSetup/Theme'
import moment from 'moment'
import qrcode from 'qrcode'

import { useUserData, formatUSD } from '../dashboard/_utils'
import ACHDetails from './ach/_achDetails'
import BankAccountLinkForm from './wire/_bankAccountLinkForm'
import WireDetails from './wire/_wireDetails'
import { fetchUserData } from 'actions/User'

import VerifyPage from './_verify'

import copy from 'copy-to-clipboard'

import '_appSetup/Global.css'

const FAQ = [
  { q: 'What is USDC?', link: 'https://www.coinbase.com/usdc' },
  { q: 'How does depositing USDC work?', link: 'https://stablegains.zendesk.com/hc/en-us/articles/4410414373521-How-does-depositing-with-USDC-work-' },
  { q: 'Are there any fees?', link: 'https://stablegains.zendesk.com/hc/en-us/articles/4402680357905-Do-you-have-any-deposit-or-withdrawal-fees-' },
  { q: `I just made a deposit. When will it show in my Stablegains balance?`, link: 'https://stablegains.zendesk.com/hc/en-us/articles/4405834129297-I-just-made-a-deposit-but-it-doesn-t-show-in-my-Stablegains-balance-' },
]

const USDCInstructions = (props) => {
  const styles = useStyles(props)
  const [qrUrl, setQrUrl] = useState()
  const [copied, setCopied] = useState(false)
  const [generateLoading, setGenerateLoading] = useState(false)
  const { UserData } = useUserData()

  const address = UserData.depositAddress

  const qrCodeUrl = address && `ethereum:${address}`

  useEffect(() => {
    qrcode.toDataURL(qrCodeUrl, (err, imageUrl) => {
      if (err) {
        return
      }
      setQrUrl(imageUrl)
    })
  }, [qrCodeUrl])

  const copyButtonPressed = () => {
    copy(address)
    setCopied(true)
  }

  const generateAddress = async () => {
    setGenerateLoading(true)
    const generateFunc = firebase.functions().httpsCallable('generateWalletForUser')
    await generateFunc({ uid: UserData.uid, email: UserData.email })
    await fetchUserData({})
    setGenerateLoading(false)
  }

  return (
    <>
      <Text style={styles.topInstruction}>Send USDC from your exchange app or crypto wallet to the address below.</Text>

      {address ? (
        <Grid container>

          <Grid item xs={12} sm className={styles.qrimgWrapper}>
            <img src={qrUrl} className={styles.qrimg}/>
          </Grid>

          <Grid item xs={12} sm className={styles.addressWrapper}>
            <Text variant='subtitle2' style={styles.label} gutterBottom>Your USDC address (Ethereum/ERC20 network):</Text>
            <Text gutterBottom style={styles.address}>{address}</Text>
            <Button style={styles.copyButton} disabled={copied} variant='outlined' color='secondary' text={copied ? 'Copied!' : 'Copy USDC address'} onClick={copyButtonPressed}/>

          </Grid>
        </Grid>
      ) : (
        <Button style={styles.copyButton} loading={generateLoading} variant='contained' color='secondary' text={'Generate USDC address'} onClick={() => generateAddress()}/>
      )}


      <Text variant='subtitle2' style={styles.disclaimer}>Only send USDC over the Ethereum network (ERC20) to this address. Other tokens (including ETH and other stablecoins) are not supported and you risk losing your funds.</Text>


      <Text variant='subtitle2' style={`${styles.subheader} ${styles.separator}`}>Need help? Find answers to the most common questions below:</Text>

      <ul>

        {FAQ.map(f => {
          return (<li key={f.q}>
            <Text gutterBottom>
              <a href={f.link} onClick={() => {
                analytics.track('Deposit FAQ link', { question: f.q, url: f.link })
              }} target='_blank'>
                {f.q}
              </a>
            </Text>
          </li>)
        })}

      </ul>
    </>
  )
}

const WireInstructions = (props) => {
  const styles = useStyles(props)

  const { UserData } = useUserData()

  const linkedBankAccount = UserData.linkedBankAccount

  if (linkedBankAccount) return <WireDetails page='deposit'/>

  return <BankAccountLinkForm/>
}

const DepositPage = (props) => {
  const styles = useStyles(props)
  const [selectedTab, setSelectedTab] = useState(0)

  const { UserData } = useUserData()

  const isBusiness = UserData?.accountType == 'BUSINESS'
  const tabNames = isBusiness ? ['Wire transfer', 'ACH transfer', 'USDC'] : ['ACH transfer', 'Wire transfer', 'USDC']

  const LeftComponent = () => {
    if (UserData?.EMERGENCY_MODE) {
      return (
        <View style={styles.leftWrapper}>

          <Text variant='h5' gutterBottom style={styles.header}>Make a deposit</Text>

          <Text gutterBottom>Due to market volatility, all deposit facilities are currently suspended.</Text>
          <Text gutterBottom>All scheduled recurring ACH deposits have been canceled.</Text>

        </View>
      )
    }

    return (
      <View style={styles.leftWrapper}>

        <Text variant='h5' gutterBottom style={styles.header}>Choose your deposit method:</Text>

        <Tabs
          value={selectedTab}
          onChange={(e, v) => {
            analytics.track('Deposit Method Tab Selected', { depositMethodEnum: v, tabName: tabNames[v] })
            setSelectedTab(v)
          } }
          indicatorColor='primary'
          textColor='primary'
          variant='scrollable'
        >
          {tabNames.map((p, i) => {
            return (
              <Tab key={i} disableRipple label={p}/>
            )
          })}
        </Tabs>

        {tabNames[selectedTab] == 'ACH transfer' ? <ACHDetails page={'deposit'}/> :
          tabNames[selectedTab] == 'Wire transfer' ? <WireInstructions setSelectedTab={setSelectedTab}/> :
            <USDCInstructions/> }


      </View>
    )
  }

  const RightComponent = () => {
    const depositData = UserData.transactions?.filter(t => t.type == 'DEPOSIT') || []

    const sortedDepositData = depositData.sort((a, b) => moment(b.date, 'YYYY-MM-DD').unix() - moment(a.date, 'YYYY-MM-DD').unix())

    const tableData = sortedDepositData.map(dep => {
      let amountString = formatUSD(dep.amount)
      if (dep.status == 'PENDING') amountString += ' (processing)'
      return [moment(dep.date, 'YYYY-MM-DD').format('DD MMM YYYY'), amountString]
    })

    return (
      <View style={styles.leftWrapper}>
        <Text gutterBottom style={styles.textHeader}>Previous deposits</Text>
        <Table head={['Date', 'Amount']} rows={tableData} noDataCaption="We haven't received deposits from you yet."/>
      </View>
    )
  }

  const showVerifyOverlay = !UserData.transactions && UserData?.KYC != 'VERIFIED'

  return (
    <Page>
      <SEO title='Deposit'/>
      <WhiteGreyLayout
        leftComponent={LeftComponent}
        rightComponent={RightComponent}
      />

      {showVerifyOverlay && <VerifyPage/>}
    </Page>
  )
}


const useStyles = makeStyles({
  leftWrapper: {
    padding: theme.spacing(4),
    paddingTop: theme.spacing(6),
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(4),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  subheader: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(0.5),
    opacity: 0.5,
  },
  separator: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(0.5),
    borderTop: '1px solid #00000016',
    paddingTop: theme.spacing(4),
  },
  copyButton: {
    marginLeft: 0,
    textAlign: 'center',
    marginRight: theme.spacing(2),
  },
  header: {
    marginBottom: theme.spacing(3),
  },
  textHeader: {
    fontSize: 12,
    fontWeight: 600,
    marginBottom: theme.spacing(2),
  },
  row: {
    ...theme.row,
    alignItems: 'center',
  },
  tabHeader: {
    marginBottom: theme.spacing(2),
  },
  topInstruction: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  addressRow: {
    display: 'flex',
    alignItems: 'center',
  },
  address: {
    overflowWrap: 'break-word',
    width: '100%',
    maxWidth: '100%',
  },
  addressWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  qrimgWrapper: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '164px',
      marginRight: theme.spacing(1),
    },
  },
  qrimg: {
    width: '164px',
    height: '164px',
  },
  label: {
    color: `${theme.palette.primary.main}88`,
  },
  disclaimer: {
    color: `${theme.palette.primary.main}88`,
    marginTop: theme.spacing(2),
  },
})

export default DepositPage
