import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Capacitor } from '@capacitor/core'

import { Dialog, DialogContent, DialogTitle, DialogActions } from '@material-ui/core'
import { Text, analytics, Button, Input } from 'lib'
import { loadStripe } from '@stripe/stripe-js'
import firebase from 'gatsby-plugin-firebase'
import { navigate } from 'gatsby'

import { useUserData } from '../dashboard/_utils'
import { isValid as isValidSSN } from 'ssn-validator'

import '_appSetup/Global.css'

const stripePromise = loadStripe('pk_live_51IycI9FrfJuyEo71Hd8rp7DGba3ZIkTJS8rYSjS85IuQDT3usE833gTteTjznJZKUTieFndpDiZPpqRq7U1stF3l00BymmblQq')

const VerifyButton = (props) => {
  const [stripeObj, setStripeObj] = useState(null)
  const { UserData } = useUserData()
  const styles = useStyles(props)

  const initStripe = async () => {
    const stripe = await props.stripePromise
    setStripeObj(stripe)
  }

  const verifyClicked = async (event) => {
    props.setVerificationStatus('PENDING')
    // Block native event handling.

    try {
      event.preventDefault()
    } catch {
      // sometimes browsers crash
    }

    if (!stripeObj) {
      // Stripe.js has not loaded yet. Make sure to disable
      // the button until Stripe.js has loaded.
      return
    }

    let url,
      clientSecret

    if (UserData.verificationSession?.id) {
      // verification has already been attempted, reuse the session object
      const id = UserData.verificationSession.id
      const verifyFunc = firebase.functions().httpsCallable('retrieveVerificationSession')
      const response = await verifyFunc({ id })
      url = response.data.result.url
      clientSecret = response.data.result.client_secret
    } else {
      // Call the backend to create the VerificationSession.
      const verifyFunc = firebase.functions().httpsCallable('createVerificationSession')
      const response = await verifyFunc()
      url = response.data.result.url
      clientSecret = response.data.result.client_secret
    }

    if (Capacitor.isNativePlatform()) {
      window.location = url
    } else {
      // Show the verification modal.
      const verificationResult = await stripeObj.verifyIdentity(clientSecret)

      if (verificationResult.error) {
        props.setVerificationStatus('FAILED')
      } else {
        props.setVerificationStatus('SUBMITTED')
      }
    }

  }

  useEffect(() => {
    initStripe()
  }, [])

  if (!stripeObj) return null
  if (props.verificationStatus == 'SUBMITTED') return null

  return (
    <Button
      variant='contained'
      color='secondary'
      loading={!props.verificationStatus || props.verificationStatus == 'PENDING'}
      disabled={props.buttonDisabled}
      onClick={() => {
        analytics.track('Start Identity Verification Button')
        verifyClicked()
      }}>
        Continue
    </Button>
  )
}

const VerifyPageBusiness = (props) => {
  const { UserData } = useUserData()
  const styles = useStyles(props)

  return (
    <Dialog
      open={UserData.KYC != 'VERIFIED'}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>{'One final step'}</DialogTitle>

      <DialogContent>
        <Text gutterBottom text={'We need a little more information about your business for legal compliance. It typically takes less than 10 minutes to complete.'}/>
        <Button
          variant='contained'
          style={styles.completeOnboardingButton}
          href='https://airtable.com/shrZQo96bDMxwhZQd'
          target='_blank'>
            Complete onboarding
        </Button>
        <Text>We'll review your information within 2 business days, after which you'll be ready to start earning. If you need help, please write us on support@stablegains.com or book a call <a href='https://calendly.com/stablegains-business/onboarding' target='_blank'>here</a>.</Text>
      </DialogContent>


      <DialogActions>


        <Button
          color='secondary'
          onClick={() => {
            navigate('/dashboard')
          }}>
            Close
        </Button>

      </DialogActions>
    </Dialog>
  )
}

const VerifyPage = (props) => {
  const { UserData } = useUserData()
  const UserDataLoading = !UserData || !UserData.email

  const styles = useStyles(props)
  const [refreshButtonVisible, setRefreshButtonVisible] = useState(false)
  const [verificationStatus, setVerificationStatus] = useState(null) // NOT_STARTED, PENDING, SUBMITTED, FAILED
  const [SSN, setSSN] = useState(UserData.SSN || '')
  const [page, setPage] = useState(0)

  useEffect(() => {
    if (!UserDataLoading) {
      if (['KYC_VERIFIED_AML_NOT_VERIFIED', 'KYC_VERIFIED_WRONG_STATE', 'FAILED_AUTO_VERIFICATION'].includes(UserData?.KYC)) {
        setVerificationStatus('SUBMITTED')
      } else {
        setVerificationStatus('NOT_STARTED')
      }
    }
  }, [UserDataLoading])

  useEffect(() => {
    if (verificationStatus == 'PENDING') saveSSN()

    if (['PENDING', 'SUBMITTED'].includes(verificationStatus)) {
      setTimeout(() => {
        setRefreshButtonVisible(true)
        setPage(2)
      }, verificationStatus == 'PENDING' ? 10000 : 0)
    } else if (['FAILED'].includes(verificationStatus)) {
      setRefreshButtonVisible(false)
    }
  }, [verificationStatus])

  if (verificationStatus && UserData.accountType == 'BUSINESS') return <VerifyPageBusiness/>

  const text = {
    NOT_STARTED: { heading: 'One final step', description: 'Before you can deposit and start earning, we need to do a quick ID check. Click the button below to get started.' },
    PENDING: { heading: 'One final step', description: 'Before you can deposit and start earning, we need to do a quick ID check. Click the button below to get started.' },
    SUBMITTED: { heading: 'Verification in progress!', description: `Your documents are submitted. We'll email you when the process is complete, or if we need any further information.` },
    FAILED: { heading: 'Verification failed', description: 'Your ID check attempt has failed. Please try again.' },
  }

  const reload = () => {
    if (typeof window != 'undefined') window.location.reload()
  }

  const saveSSN = () => {
    const ssnFunc = firebase.functions().httpsCallable('setSSN')
    ssnFunc({ SSN })
  }

  const validSSNEntered = isValidSSN(SSN)

  return (
    <Dialog
      open={verificationStatus}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>{text[verificationStatus]?.heading || 'Loading...'}</DialogTitle>

      <DialogContent>
        {page == 1 ? (
          <>
            <Text text={`We need a few pieces of information required by regulations.`} style={styles.label}/>
            <Input
              value={SSN}
              onChange={(e) => setSSN(e.target.value)}
              name='SSN'
              autoFocus
              label={'What is your SSN (social security number)?'}
              type={'password'}
              size='small'
              fullWidth
              style={styles.input}
            />
          </>
        ) : (
          <>
            <Text text={text[verificationStatus]?.description || 'Loading...'}/>
            {['NOT_STARTED', 'PENDING'].includes(verificationStatus) && <Text style={styles.subheader} gutterBottom variant={'subtitle2'} text={'To help the government fight the funding of terrorism and money laundering activities, U.S. federal law requires all financial institutions to obtain, verify, and record information that identifies each person who opens an account. What this means for you: When you open an account, we will ask for your name, address, date of birth, and other information that will allow us to identify you. We may also ask for a copy of your driver’s license or other identifying documents.'}/>}

            {UserData?.EMERGENCY_MODE && (
              <Text text={'Note: new user onboarding is currently paused. We will email you when you are able to start using Stablegains.'}/>
            )}


          </>
        ) }

      </DialogContent>

      <DialogActions>

        {page == 0 ? (
          <Button
            variant='contained'
            color='secondary'
            disabled={UserData?.EMERGENCY_MODE}
            onClick={() => {
              analytics.track('Start Identity Verification Button')
              setPage(1)
            }}>
              Start identity verification
          </Button>
        ) : (
          <VerifyButton
            buttonDisabled={!validSSNEntered}
            stripePromise={stripePromise}
            verificationStatus={verificationStatus}
            setVerificationStatus={setVerificationStatus}
          />
        )}

        { (refreshButtonVisible && verificationStatus != 'FAILED') && (
          <Button
            color='secondary'
            variant='contained'
            onClick={reload}>
              Refresh
          </Button>
        )}

        <Button
          color='secondary'
          onClick={() => {
            navigate('/dashboard')
          }}>
            Close
        </Button>

      </DialogActions>
    </Dialog>
  )
}


const useStyles = makeStyles(theme => ({
  circlePlaceholder: {
    height: '24px',
  },
  completeOnboardingButton: {
    marginLeft: 0,
    marginRight: 0,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  input: {
    minHeight: 80,
    marginBottom: 0,
  },
  subheader: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    opacity: 0.5,
  },
}))

export default VerifyPage
